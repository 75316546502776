import React from "react";
import { Link } from "gatsby";
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
  return (
    <footer className="bg-primary" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">Footer</h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <Solutions/>
              </div>
              <div className="mt-12 md:mt-0">
                <Support/>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <Company/>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <ContactForm/>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            <SocialIcon url="https://twitter.com/waivpay" bgColor="transparent" fgColor="white"/>
            <SocialIcon url="https://www.linkedin.com/company/waivpay" bgColor="transparent" fgColor="white"/>
          </div>
          <p className="mt-8 text-base text-gray-300 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} Waivpay Ltd
          </p>
        </div>
      </div>
    </footer>
  )
}

const Solutions = () => (
  <>
    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
      Solutions
    </h3>
    <ul className="mt-4 space-y-4">
      <li>
        <Link to="/solutions/shopping-centres" className="text-base text-gray-300 hover:text-white">
          Shopping Centres
        </Link>
      </li>

      <li>
        <Link to="/solutions/corporate" className="text-base text-gray-300 hover:text-white">
          Corporate
        </Link>
      </li>

      <li>
        <Link to="/solutions/government" className="text-base text-gray-300 hover:text-white">
          Government
        </Link>
      </li>
    </ul>
  </>
);

const Support = () => (
  <>
    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
      Support
    </h3>
    <ul className="mt-4 space-y-4">
      <li>
        <a href="/balance-check" className="text-base text-gray-300 hover:text-white">
          Balance&nbsp;Check
        </a>
      </li>

      <li>
        <a href="/contact" className="text-base text-gray-300 hover:text-white">
          Contact
        </a>
      </li>

      <li>
        <Link to="/terms" className="text-base text-gray-300 hover:text-white">
          Terms
        </Link>
      </li>

      <li>
        <Link to="/privacy" className="text-base text-gray-300 hover:text-white">
          Privacy
        </Link>
      </li>
    </ul>
  </>
);

const Company = () => (
  <>
    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
      Company
    </h3>
    <ul className="mt-4 space-y-4">
      <li>
        <Link to="/locations" className="text-base text-gray-300 hover:text-white">
          Locations
        </Link>
      </li>

      <li>
        <Link to="/about" className="text-base text-gray-300 hover:text-white">
          About
        </Link>
      </li>

      <li>
        <Link to="/about/board" className="text-base text-gray-300 hover:text-white">
          Board
        </Link>
      </li>

      <li>
        <Link to="/about/team" className="text-base text-gray-300 hover:text-white">
          Team
        </Link>
      </li>
    </ul>
  </>
);

const ContactForm = () => (
  <>
    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
      Contact us
    </h3>
    <p className="mt-4 text-base text-gray-300">
      Create shopper traffic, connect with your customers, and have your own closed-loop loyalty and payments
    </p>
    <div className="mt-4 sm:flex sm:max-w-md">
      <div className="mt-3 rounded-md sm:mt-0 sm:flex-shrink-0">
        <button type="submit" className="w-full bg-highlight border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-highlight-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-highlight">
          <Link to="/contact">Let's Talk</Link>
        </button>
      </div>
    </div>
  </>
);

export default Footer;
